<template>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m18 2c-2.2091 0-4 1.7909-4 4h-2c-1.5913 0-3.1174 0.63214-4.2426 1.7574s-1.7574 2.6513-1.7574 4.2426v28c0 1.5913 0.63214 3.1174 1.7574 4.2426 1.1252 1.1253 2.6513 1.7574 4.2426 1.7574h24c1.5913 0 3.1174-0.6321 4.2426-1.7574 1.1253-1.1252 1.7574-2.6513 1.7574-4.2426v-28c0-1.5913-0.6321-3.1174-1.7574-4.2426-1.1252-1.1252-2.6513-1.7574-4.2426-1.7574h-2c0-2.2091-1.7909-4-4-4h-12zm16 8c0 2.2091-1.7909 4-4 4h-12c-2.2091 0-4-1.7909-4-4h-2c-0.5304 0-1.0391 0.2107-1.4142 0.5858s-0.5858 0.8838-0.5858 1.4142v28c0 0.5304 0.2107 1.0391 0.5858 1.4142s0.8838 0.5858 1.4142 0.5858h24c0.5304 0 1.0391-0.2107 1.4142-0.5858s0.5858-0.8838 0.5858-1.4142v-28c0-0.5304-0.2107-1.0391-0.5858-1.4142s-0.8838-0.5858-1.4142-0.5858h-2zm-16-2v2h12v-4h-12v2z"
      clip-rule="evenodd"
      fill="currentColor"
      fill-rule="evenodd"
    />
    <path
      d="m31.414 16.586c0.7811 0.781 0.7811 2.0474 0 2.8284l-8 8c-0.781 0.7811-2.0474 0.7811-2.8284 0l-4-4c-0.7811-0.781-0.7811-2.0474 0-2.8284 0.781-0.7811 2.0474-0.7811 2.8284 0l2.5858 2.5858 6.5858-6.5858c0.781-0.7811 2.0474-0.7811 2.8284 0z"
      clip-rule="evenodd"
      fill="currentColor"
      fill-rule="evenodd"
    />
    <path
      d="m31.414 26.586c0.7811 0.781 0.7811 2.0474 0 2.8284l-8 8c-0.781 0.7811-2.0474 0.7811-2.8284 0l-4-4c-0.7811-0.781-0.7811-2.0474 0-2.8284 0.781-0.7811 2.0474-0.7811 2.8284 0l2.5858 2.5858 6.5858-6.5858c0.781-0.7811 2.0474-0.7811 2.8284 0z"
      clip-rule="evenodd"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconClipboard',
}
</script>
