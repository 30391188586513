<template>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m23.298 2.1273c0.4527-0.16979 0.9517-0.16979 1.4044 0l16 6c0.7807 0.29273 1.2978 1.039 1.2978 1.8727v14c0 6.8929-4.5635 12.401-8.683 16.005-2.1018 1.839-4.1941 3.2765-5.757 4.2533-0.7835 0.4897-1.4394 0.867-1.9039 1.1241-0.2324 0.1286-0.4172 0.2274-0.5467 0.2954-0.0648 0.034-0.1157 0.0603-0.1519 0.0788l-0.043 0.0219-0.0129 0.0066-0.0043 0.0021c-7e-4 4e-4 -0.0029 0.0015-0.8973-1.7874-0.8944 1.7889-0.895 1.7886-0.8957 1.7882l-0.0059-0.0029-0.0129-0.0066-0.043-0.0219c-0.0362-0.0185-0.0871-0.0448-0.1519-0.0788-0.1295-0.068-0.3143-0.1668-0.5467-0.2954-0.4645-0.2571-1.1204-0.6344-1.9039-1.1241-1.5629-0.9768-3.6552-2.4143-5.757-4.2533-4.1195-3.6046-8.683-9.1123-8.683-16.005v-14c0-0.83369 0.51715-1.5799 1.2978-1.8727l16-6zm0.7022 41.873-0.8957 1.7882c0.5631 0.2816 1.2271 0.2822 1.7901 7e-4l-0.8944-1.7889zm0-2.2745c0.3833-0.2172 0.8734-0.5049 1.44-0.859 1.4371-0.8982 3.3448-2.2107 5.243-3.8717 3.8805-3.3954 7.317-7.8877 7.317-12.995v-12.614l-14-5.25-14 5.25v12.614c0 5.1071 3.4365 9.5994 7.317 12.995 1.8982 1.661 3.8059 2.9735 5.243 3.8717 0.5666 0.3541 1.0567 0.6418 1.44 0.859z"
      clip-rule="evenodd"
      fill="currentColor"
      fill-rule="evenodd"
    />
    <path
      d="m24 13.5c-0.7809 0-1.5298 0.3102-2.082 0.8624s-0.8624 1.3011-0.8624 2.082v2.0556h5.8888v-2.0556c0-0.7809-0.3102-1.5298-0.8624-2.082s-1.3011-0.8624-2.082-0.8624zm5.9444 5v-2.0556c0-1.5765-0.6262-3.0885-1.741-4.2033s-2.6268-1.7411-4.2034-1.7411-3.0886 0.6263-4.2034 1.7411-1.741 2.6268-1.741 4.2033v2.0556h-0.2778c-1.8103 0-3.2778 1.4675-3.2778 3.2778v6.2222c0 1.8103 1.4675 3.2778 3.2778 3.2778h12.444c1.8103 0 3.2778-1.4675 3.2778-3.2778v-6.2222c0-1.8103-1.4675-3.2778-3.2778-3.2778h-0.2778zm-12.167 3c-0.1534 0-0.2778 0.1244-0.2778 0.2778v6.2222c0 0.1534 0.1244 0.2778 0.2778 0.2778h12.444c0.1534 0 0.2778-0.1244 0.2778-0.2778v-6.2222c0-0.1534-0.1244-0.2778-0.2778-0.2778h-12.444z"
      clip-rule="evenodd"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconShield',
}
</script>
