<template>
  <div class="relative w-full">
    <t-form
      ref="form"
      class="postal-form-step"
      @submit="onSubmit"
      @submit.prevent
    >
      <PostalField
        class="postal-form-step__input"
        autocomplete="postal-code"
        :placeholder="$t('field.postal.placeholder')"
        @result="onResult"
        @change="onChange"
      />
      <AddressNumberField
        class="postal-form-step__input"
        :placeholder="$t('field.address-number.placeholder')"
        @result="onResult"
        @change="onChange"
      />
      <FormStepSubmitButton
        id="submitPostal"
        class="postal-form-step__btn t-btn--primary"
        :text="$t('global.continue')"
      />

      <ServiceField
        ref="service"
        hidden
        :init-with-value="serviceId"
        @result="onResult"
        @change="onChange"
      />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />

    <p
      class="text-white font-semibold leading-5 mt-3 text-center px-3"
      style="text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)"
    >
      {{ $t('ctaSubtitle') }}
    </p>
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import PostalField from 'chimera/all/components/form/fields/postal/PostalField'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AddressNumberField from 'chimera/all/components/form/fields/addressNumber/AddressNumberField'
import { realEstateValuationConsumerNL } from 'chimera/realEstateAgency/service'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import NextStep from '~/pages/offertes-aanvragen/start'

export default {
  name: 'PostalFormStep',

  components: {
    AddressNumberField,
    FormErrorMessages,
    PostalField,
    ServiceField,
  },

  extends: AbstractFormStep,

  /**
   * @returns {{serviceId: string}}
   */
  data() {
    return {
      serviceId: realEstateValuationConsumerNL.id,
    }
  },

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "ctaSubtitle": "Krijg gratis en geheel vrijblijvend tot zes waardebepalingen van makelaars uit jouw regio."
  },
  "en-GB": {
    "ctaSubtitle": "Get up to 6 free valuations from real state experts in your region"
  }
}
</i18n>
