<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 3V9H21"
      stroke="currentCOlor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 2L15 9"
      stroke="currentCOlor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.9999 17.9201V20.9201C21.0011 21.1986 20.944 21.4743 20.8324 21.7294C20.7209 21.9846 20.5572 22.2137 20.352 22.402C20.1468 22.5902 19.9045 22.7336 19.6407 22.8228C19.3769 22.912 19.0973 22.9452 18.8199 22.9201C15.7428 22.5857 12.7869 21.5342 10.1899 19.8501C7.77376 18.3148 5.72527 16.2663 4.18993 13.8501C2.49991 11.2413 1.44818 8.27109 1.11993 5.1801C1.09494 4.90356 1.12781 4.62486 1.21643 4.36172C1.30506 4.09859 1.4475 3.85679 1.6347 3.65172C1.82189 3.44665 2.04974 3.28281 2.30372 3.17062C2.55771 3.05843 2.83227 3.00036 3.10993 3.0001H6.10993C6.59524 2.99532 7.06572 3.16718 7.43369 3.48363C7.80166 3.80008 8.04201 4.23954 8.10993 4.7201C8.23656 5.68016 8.47138 6.62282 8.80993 7.5301C8.94448 7.88802 8.9736 8.27701 8.89384 8.65098C8.81408 9.02494 8.6288 9.36821 8.35993 9.6401L7.08993 10.9101C8.51349 13.4136 10.5864 15.4865 13.0899 16.9101L14.3599 15.6401C14.6318 15.3712 14.9751 15.1859 15.3491 15.1062C15.723 15.0264 16.112 15.0556 16.4699 15.1901C17.3772 15.5286 18.3199 15.7635 19.2799 15.8901C19.7657 15.9586 20.2093 16.2033 20.5265 16.5776C20.8436 16.9519 21.0121 17.4297 20.9999 17.9201Z"
      stroke="currentCOlor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPhoneIncoming',
}
</script>
