<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 22C12 22 20 18 20 12V5L12 2L4 5V12C4 18 12 22 12 22Z"
      stroke="currentCOlor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9583 5.875C11.4275 5.875 9.30131 7.44536 8.87082 9.54167H7.875V11.1667H8.79167V11.8333H7.875V13.4583H8.87082C9.30131 15.5546 11.4275 17.125 13.9583 17.125C14.116 17.125 14.2721 17.1189 14.4263 17.107L14.5417 17.0981V15.658L14.4034 15.6728C14.2576 15.6884 14.1091 15.6964 13.9583 15.6964C12.3075 15.6964 10.937 14.7371 10.5361 13.4583H13.625V11.8333L10.4167 11.8333V11.1667L13.625 11.1667V9.54167L10.5361 9.54167C10.937 8.26295 12.3075 7.30357 13.9583 7.30357C14.1091 7.30357 14.2576 7.3116 14.4034 7.32718L14.5417 7.34196V5.9019L14.4263 5.89299C14.2721 5.88108 14.116 5.875 13.9583 5.875Z"
      fill="currentCOlor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconEuroShield',
}
</script>
