<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 17H11"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 9H9H8"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.1313 13.496C13.3037 13.7971 13.6281 14 13.9999 14H15.9999C16.5522 14 16.9999 13.5523 16.9999 13C16.9999 12.4477 16.5522 12 15.9999 12H14.6273L13.1313 13.496Z"
      fill="currentColor"
    />
    <path
      d="M8.97056 12H8C7.44772 12 7 12.4477 7 13C7 13.2687 7.10594 13.5126 7.27833 13.6922L8.97056 12Z"
      fill="currentColor"
    />
    <path
      d="M4.2157 22.4117C4.729 22.7915 5.35375 23 6 23H18C18.7957 23 19.5587 22.6839 20.1213 22.1213C20.6839 21.5587 21 20.7957 21 20V8C21 7.73478 20.8946 7.48043 20.7071 7.29289L20.0208 6.6066L18.6066 8.02081L19 8.41421V20C19 20.2652 18.8946 20.5196 18.7071 20.7071C18.5196 20.8946 18.2652 21 18 21H6C5.89014 21 5.78215 20.9819 5.68 20.9474L4.2157 22.4117Z"
      fill="currentColor"
    />
    <path
      d="M15.7782 5.19239L13.5858 3H6C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V15.9706L3 17.9706V4C3 3.20435 3.31607 2.44129 3.87868 1.87868C4.44129 1.31607 5.20435 1 6 1H14C14.2652 1 14.5196 1.10536 14.7071 1.29289L17.1924 3.77817L15.7782 5.19239Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.5061 1.29289C22.8966 1.68342 22.8966 2.31658 22.5061 2.70711L2.70711 22.5061C2.31658 22.8966 1.68342 22.8966 1.29289 22.5061C0.902369 22.1156 0.902369 21.4824 1.29289 21.0919L21.0919 1.29289C21.4824 0.902369 22.1156 0.902369 22.5061 1.29289Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconNoContract',
}
</script>
