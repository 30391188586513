<template>
  <div class="page-index">
    <Header class="header--center">
      <div class="flex flex-col justify-center">
        <PostalFormStep :progress-value="0" />
      </div>
    </Header>

    <HomeBlock id="usps" class="py-12 md:pb-16">
      <h2 class="font-normal text-center leading-6 text-primary text-lg">
        {{ $t('uspTitle') }}
      </h2>

      <p class="mt-4 md:mt-6 font-normal leading-6 text-lg md:text-center">
        {{ $t('uspSubtitle') }}
      </p>

      <div class="mt-10 w-full flex flex-col md:flex-row gap-y-9 md:space-x-6">
        <div
          v-for="usp of usps"
          :key="usp.text"
          class="flex md:flex-col items-center md:w-1/3"
        >
          <div>
            <div
              class="flex items-center justify-center h-10 w-10 rounded-full bg-primary text-white"
            >
              <component :is="usp.icon" class="h-6 w-6" />
            </div>
          </div>
          <p class="ml-3 leading-5 md:ml-0 md:mt-3 md:text-center">
            {{ $t(usp.text) }}
          </p>
        </div>
      </div>
    </HomeBlock>
  </div>
</template>

<script>
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import HomeBlock from 'chimera/all/components/HomeBlock'
import Header from 'chimera/all/themes/jupiter/components/layout/Header'
import IconNoContract from 'chimera/all/themes/jupiter/images/icons/IconNoContract'
import IconPhoneIncoming from 'chimera/all/themes/jupiter/images/icons/IconPhoneIncoming'
import IconEuroShield from 'chimera/all/themes/jupiter/images/icons/IconEuroShield'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'

export default {
  components: {
    Header,
    HomeBlock,
    PostalFormStep,
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Dewoningwaardecheck.nl',
      headTitleTemplate: '%s',
      headDescription:
        'Vul je postcode in en vergelijk geheel gratis 6 offertes van top makelaars uit jouw regio. ✓Dé beste prijs voor jouw makelaar ✓Bespaar tot 40% ✓100% Gratis!',
      path: '/',
      usps: [
        {
          icon: IconEuroShield,
          text: 'usp1',
        },
        {
          icon: IconNoContract,
          text: 'usp2',
        },
        {
          icon: IconPhoneIncoming,
          text: 'usp3',
        },
      ],
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "ctaSubtitle": "Krijg gratis en geheel vrijblijvend tot zes waardebepalingen van makelaars uit jouw regio.",
    "uspTitle": "Elke woning is anders en uniek",
    "uspSubtitle": "Met slechts een paar details kunnen makelaars uit jouw regio contact met jou opnemen",
    "usp1": "Zekerheid op een reële waarde van een betrouwbare en erkende makelaar.",
    "usp2": "Deze aanvraag is geheel vrijblijvend en verplicht je tot niets.",
    "usp3": "Na je aanvraag nemen makelaars contact met je op voor het maken van een afspraak."
  },
  "en-GB": {
    "ctaSubtitle": "Get up to 6 free valuations from real state experts in your region",
    "uspTitle": "Getting a free valuation is as easy as 1-2-3.",
    "uspSubtitle": "With just a few details we can match you with the right specialist from your region.",
    "usp1": "Assurance on a fair value from a reliable and reputable broker.",
    "usp2" : "This application is completely non-binding and does not commit you to anything.",
    "usp3": "After your application, brokers will contact you to make an appointment."
  }
}
</i18n>
