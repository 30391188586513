<template>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m27.402 34.66c0-3.8046 3.0842-6.8888 6.8888-6.8888s6.8888 3.0842 6.8888 6.8888c0 1.8349-0.7174 3.5022-1.887 4.7368-0.0476 0.0395-0.0937 0.0816-0.1384 0.1262-0.0446 0.0446-0.0867 0.0909-0.1262 0.1385-1.2347 1.1698-2.9022 1.8873-4.7372 1.8873-3.8046 0-6.8888-3.0842-6.8888-6.8888zm13.044 8.9833c-1.751 1.2021-3.8711 1.9055-6.1554 1.9055-6.0138 0-10.889-4.8751-10.889-10.889s4.875-10.889 10.889-10.889c6.0137 0 10.889 4.8751 10.889 10.889 0 2.2841-0.7033 4.4039-1.9051 6.1548l3.5415 3.5415c0.781 0.781 0.781 2.0474 0 2.8284-0.7811 0.7811-2.0474 0.7811-2.8285 0l-3.5413-3.5414z"
      clip-rule="evenodd"
      fill="currentColor"
      fill-rule="evenodd"
    />
    <path
      d="m22.772 2.4213c0.7222-0.56173 1.7336-0.56173 2.4558 0l18 14c0.4872 0.3789 0.7721 0.9615 0.7721 1.5787v8.0035c-1.1236-1.2293-2.4802-2.242-4-2.9683v-4.057l-16-12.444-16 12.444v21.022c0 0.5304 0.21071 1.0391 0.58579 1.4142 0.37507 0.3751 0.88378 0.5858 1.4142 0.5858h6v-18c0-1.1046 0.8954-2 2-2h12c0.3414 0 0.6629 0.0856 0.944 0.2364-2.3927 0.6585-4.5092 1.9839-6.1371 3.7636h-4.8069v16h3.5952c1.0946 1.6332 2.5496 3.0044 4.2511 4h-17.846c-1.5913 0-3.1174-0.6321-4.2426-1.7574-1.1252-1.1252-1.7574-2.6513-1.7574-4.2426v-22c0-0.6172 0.28495-1.1998 0.77212-1.5787l18-14z"
      clip-rule="evenodd"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconHome',
}
</script>
