<template>
  <div class="container mt-12 md:mt-28 flex flex-col items-center">
    <p class="text-center font-semibold text-lg md:text-xl leading-6">
      {{ $t('subtitle') }}
    </p>

    <p
      class="text-center text-primary font-semibold text-2xl md:text-3xl leading-8 md:leading-12"
    >
      {{ $t('title') }}
    </p>

    <p
      class="font-normal leading-5 pt-10 pb-12 md:pb-16 md:text-center md:max-w-[400px]"
    >
      {{ $t('body') }}
    </p>

    <ul class="space-y-10 md:max-w-[250px]">
      <li>
        <div class="space-x-3 md:space-y-4 flex md:flex-col items-center">
          <div class="text-primary">
            <IconClipboard class="w-12 h-12" />
          </div>
          <div class="text-lg leading-6">
            <p class="md:text-center">
              <span class="font-semibold">{{ $t('item1-title') }}</span>
              {{ $t('item1-subtitle') }}
            </p>
          </div>
        </div>
      </li>
      <li>
        <div class="space-x-3 md:space-y-4 flex md:flex-col items-center">
          <div class="text-primary">
            <IconHome class="w-12 h-12" />
          </div>
          <div class="text-lg leading-6">
            <p class="md:text-center">
              {{ $t('item2-title') }}
              <span class="font-semibold">{{ $t('item2-subtitle') }}</span>
            </p>
          </div>
        </div>
      </li>
      <li>
        <div class="space-x-3 md:space-y-4 flex md:flex-col items-center">
          <div class="text-primary">
            <IconShield class="w-12 h-12" />
          </div>
          <div class="text-lg leading-6">
            <p class="font-semibold md:text-center">
              {{ $t('item3-title') }}
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import IconClipboard from 'chimera/all/themes/jupiter/images/icons/IconClipboard'
import IconHome from 'chimera/all/themes/jupiter/images/icons/IconHome'
import IconShield from 'chimera/all/themes/jupiter/images/icons/IconShield'
import NextStep from '~/pages/offertes-aanvragen/adresgegevens'

export default {
  name: 'StartFormStep',

  components: {
    IconClipboard,
    IconHome,
    IconShield,
  },

  extends: AbstractFormStep,

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Een lokale makelaar weet precies wat jouw huis waard is.",
    "subtitle": "Zekerheid op een reële waardeschatting.",
    "body": "Direct contact heeft verschillende voordelen ten opzichte van een online rapport:",
    "item1-title": "Zekerheid op een reële waardering,",
    "item1-subtitle": "geen schatting.",
    "item2-title": "Laat je informeren door maximaal 6 makelaars,",
    "item2-subtitle": "gratis en zonder verplichtingen.",
    "item3-title": "Veilig gebruik van je gegevens."
  },
  "en-GB": {
    "title": "A local real estate agent knows exactly what your home is worth.",
    "subtitle": "Assurance on a fair value estimate.",
    "body": "An expert valuation has several advantages when compared with an online estimation check:",
    "item1-title": "Real Valuation,",
    "item1-subtitle": "not an estimation.",
    "item2-title": "Get contacted by up to 6 specialists,",
    "item2-subtitle": "for free and with no obligations.",
    "item3-title": "Safe use of your data."
  }
}
</i18n>
