<template>
  <FormModal class="form-modal--intro">
    <template #body>
      <StartFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/jupiter/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import StartFormStep from '~/components/form/steps/start/StartFormStep'

export default {
  components: {
    StartFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Start vragenlijst',
      headDescription:
        'We gaan je nu een paar vragen stellen. Hierdoor kunnen we makelaars vinden die bij jou passen.',
      path: '/offertes-aanvragen/start',
      progressValue: 0,
      checkoutStep: 1,
    }
  },
}
</script>
